<template>
  <q-table
    title="Campanhas aprovadas"
    :data="approvedCampaigns"
    row-key="site_id+id"
    flat
    bordered
    :columns="columnsApprovedCampaigns"
    :pagination="pagination"
    >
    <template v-slot:body-cell-cap="props">
      <q-td :props="props">
        <q-btn unelevated round flat size="sm" icon="mdi-pencil-outline" color="primary" :disabled="disableEdit">
          <q-popup-edit
            v-model="props.row"
            title="Editar CAP"
            v-slot="scope"
            :cover="false"
            :offset="[0, -150]"
            max-width="300px"
            persistent
            @before-show="disableEdit = true"
            @before-hide="disableEdit = false"
          >
            <q-btn
              class="absolute-top-right q-mt-sm q-mr-sm"
              dense
              flat
              round
              icon="mdi-close"
              @click="
                scope.value.sale_limit = scope.initialValue.sale_limit;
                scope.value.sale_limit_metric = scope.initialValue.sale_limit_metric;
              "
              @click.stop="scope.cancel"
            />

            <div class="row q-col-gutter-sm q-mb-md col-12 justify-around">
              <q-input class="col-5" mask="######" v-model="scope.value.sale_limit" dense outlined autofocus label="Conversões" />
              <q-select
                label="Período"
                class="col-6"
                v-model="scope.value.sale_limit_metric"
                :options="list_sale_metric"
                option-label="label"
                option-value="value"
                dense
                outlined
                emit-value
                map-options
              />
            </div>
            <div class="row justify-end">
              <q-btn
                class="q-px-sm"
                no-caps
                outline
                dense
                label="Deletar"
                color="negative"
                @click="delCap(scope)"
                @click.stop="scope.cancel"
                :disabled="scope.initialValue?.sale_limit === null && scope.initialValue?.sale_limit_metric === null"
              />

              <q-btn
                class="q-ml-lg q-px-lg"
                no-caps
                dense
                label="Salvar"
                color="primary"
                :disabled="disableBtnSave(scope)"
                @click="editCap(scope)"
                @click.stop="scope.cancel"
              />
            </div>
          </q-popup-edit>

          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('edit') }} CAP
          </q-tooltip>
        </q-btn>
        {{
          props.row.sale_limit && props.row.sale_limit_metric
            ? `${props.row.sale_limit} ${
                list_sale_metric.find((item) => item.value === props.row.sale_limit_metric)?.label ||
                props.row.sale_limit_metric.label
              }`
            : 'Não definido'
        }}
      </q-td>
    </template>

    <template v-slot:bottom>
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import AdvertiserMixin from '@m/AdvertiserMixin';
import AffiliateService from '../../../services/AffiliateService';

export default {
  mixins: [AdvertiserMixin, AffiliateService],

  data() {
    return {
      disableEdit: false,
      list_sale_metric: [
        {
          label: 'Diário',
          value: 'day'
        },
        {
          label: 'Semanal',
          value: 'week'
        },
        {
          label: 'Mensal',
          value: 'month'
        }
      ],
      pagination: {
        sortBy: 'site_id',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.$emit('changePage', `page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },
    columnsApprovedCampaigns() {
      return [
        {
          name: 'Canal ID',
          required: true,
          label: 'Canal ID',
          align: 'left',
          field: 'site_id'
        },
        {
          name: 'Canal',
          align: 'left',
          label: 'Canal',
          field: 'site_name'
        },
        {
          align: 'left',
          name: 'Campanha ID',
          label: 'Campanha ID',
          field: 'id'
        },
        {
          align: 'left',
          name: 'Campanha',
          label: 'Campanha',
          field: 'name'
        },
        {
          align: 'left',
          name: 'status',
          label: 'Status',
          field: 'status',
          format: (val) => this.formatStatus(val)
        },
        {
          align: 'center',
          name: 'cap',
          label: 'CAP'
        }
      ];
    }
  },
  methods: {
    formatStatus(value) {
      switch (value) {
        case 0:
          return 'Pendente';
        case 1:
          return 'Aprovado';
        case 2:
          return 'Pausado';
        case 3:
          return 'Recusado';
        default:
          return 'Não Definido';
      }
    },

    disableBtnSave(scope) {
      return (
        scope.value.sale_limit === null ||
        scope.value.sale_limit === '' ||
        scope.value.sale_limit === '0' ||
        scope.value.sale_limit_metric === null
      );
    },

  }
};
</script>
